import $api from './api'
import { TIMEOUT, ERR_OK, baseURL, STATUS, local } from './config'

/**
 * 任务协同情况统计
 */
export const statisticsTaskStatus = (params) => {
    return $api.get({
        url: baseURL + '/common/taskCollaboration/statisticsTaskStatus',
        params
    })
}

/**
 * 查询部门列表
 */
export const departmentList = (params) => {
    return $api.get({
        url: baseURL + '/common/taskCollaboration/departmentList',
        params
    })
}

/**
 * 任务协同新增趋势
 */
export const taskAdd = (params) => {
    return $api.post({
        url: baseURL + '/common/taskCollaboration/taskAdd',
        params
    })
}

/**
 * 任务协同累计趋势
 */
export const taskCumulative = (params) => {
    return $api.post({
        url: baseURL + '/common/taskCollaboration/taskCumulative',
        params
    })
}

/**
 *  任务协同按城市/地区统计
 */
export const taskCityStatistics = (params) => {
    return $api.post({
        url: baseURL + '/common/taskCollaboration/taskCityStatistics',
        params
    })
}

/**
 *  任务协同分布图-各部门执行人员统计和任务数量按状态统计
 */
export const taskSituationByDep = (params) => {
    return $api.get({
        url: baseURL + '/common/taskCollaboration/taskSituationByDep',
        params
    })
}

