<template>
  <div
    :style="{
      height: height,
      width: width,
      position: 'absolute',
      top: '0%',
      left: '0%',
    }"
    class="bg"
    ref="chart"
  ></div>
</template>
<script>
import { debounce } from "lodash";
const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
      type: Object,
      default: () => {
        return {
          seriesData: [
            {
              name: "政法委",
              value: 1,
              com: "人",
            },
            {
              name: "政府办",
              value: 1,
              com: "人",
            },
            {
              name: "信访办",
              value: 1,
              com: "人",
            },
            {
              name: "公安分局",
              value: 1,
              com: "人",
            },
            {
              name: "市场监管局",
              value: 1,
              com: "人",
            },
            {
              name: "体育局",
              value: 1,
              com: "人",
            },
            {
              name: "教育局",
              value: 1,
              com: "人",
            },
            {
              name: "商务委",
              value: 1,
              com: "人",
            },
            {
              name: "交通办",
              value: 1,
              com: "人",
            },
            {
              name: "科委",
              value: 1,
              com: "人",
            },
            {
              name: "卫健委",
              value: 1,
              com: "人",
            },
            {
              name: "金融办",
              value: 1,
              com: "人",
            },
            {
              name: "委网信办",
              value: 1,
              com: "人",
            },
            {
              name: "人社局",
              value: 1,
              com: "人",
            },
            {
              name: "文旅局",
              value: 1,
              com: "人",
            },
            {
              name: "应急管理局",
              value: 1,
              com: "人",
            },
            {
              name: "消防支队",
              value: 1,
              com: "人",
            },
          ],
        };
      },
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
  },

  data() {
    return {
      colors: [
        "rgba(88,113,192,.5)",
        "rgba(158,202,127,0.5)",
        "rgba(241,201,107,0.5)",
        "rgba(223,110,106,0.5)",
        "rgba(133,192,219,0.5)",
        "rgba(89,158,118,0.5)",
        "rgba(235,137,93,0.5)",
        "rgba(147,100,175,.5)",
        "rgba(220,131,200,0.5)",
        "rgba(116,251,253,0.5)",
        "rgba(116,250,139,0.5)",
        "rgba(255,253,84,.5)",
        "rgba(210,59,87,0.5)",
        "rgba(97,211,212,0.5)",
        "rgba(98,164,247,0.5)",
        "rgba(231,155,55,.5)",
        "rgba(252,240,80,.5)",
      ], //['#00ff5a', '#26fff7']
      borderColor: [
        "rgba(88,113,192,1)",
        "rgba(158,202,127,1)",
        "rgba(241,201,107,1)",
        "rgba(223,110,106,1)",
        "rgba(133,192,219,1)",
        "rgba(89,158,118,1)",
        "rgba(235,137,93,1)",
        "rgba(147,100,175,1)",
        "rgba(220,131,200,1)",
        "rgba(116,251,253,1)",
        "rgba(116,250,139,1)",
        "rgba(255,253,84,1)",
        "rgba(210,59,87,1)",
        "rgba(97,211,212,1)",
        "rgba(98,164,247,1)",
        "rgba(231,155,55,1)",
        "rgba(252,240,80,1)",
      ],
      faultByHourIndex: 0,
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        if (val) {
          this.initCharts();
        }
      },
      deep: true,
    },
  },
  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);
      const series = _this.chartData.seriesData.map((s, i) => {
        let item = {
          name: s.name,
          value: s.value,
          com: s.com,
          itemStyle: {
            normal: {
              borderColor: _this.borderColor[i],
              borderWidth: 2,
              color: _this.colors[i],
            },
          },
          label: {
            normal: {
              show: false,
              padding: [13, -40, 15, -60],
              lineHeight: 22,
              formatter: (params) => {
                return (
                  "{bottom|" +
                  params.data.value +
                  "}{bottomcom|" +
                  params.data.com +
                  "}\n{top| " +
                  params.name +
                  "}"
                );
              },
              rich: {
                top: {
                  color: "white",
                  fontSize: 14,
                },
                bottom: {
                  color: _this.borderColor[i],
                  fontSize: 22,
                },
                bottomcom: {
                  color: "white",
                  fontSize: 14,
                },
              },
            },
            emphasis: {
              show: true,
            },
          },
        };
        return { ...item };
      });
      this.chart.setOption({
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["25%", "42%"],
            avoidLabelOverlap: false,
            minAngle: 15,
            labelLine: {
              normal: {
                show: false,
                length: 30,
                length2: 80,
              },
              emphasis: {
                show: true,
              },
            },
            data: series,
          },
        ],
      });
      this.chart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.faultByHourIndex,
      });

      this.chart.on("mouseover", function (d) {
        _this.chart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
        });
        _this.chart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: d.dataIndex,
        });
        _this.faultByHourIndex = d.dataIndex;
      });

      this.chart.on("mouseout", function () {
        _this.chart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: _this.faultByHourIndex,
        });
        _this.chart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
        });

        _this.chart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: _this.faultByHourIndex,
        });
      });
    },
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  mounted() {
    this.initCharts();

    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);

    // let _this = this;
    // this.chart.on("click", function (params) {
    //   if (params.data.type == "supervise") {
    //     _this.$emit("getClickData", params.name);
    //   } else if (params.data.type == "place") {
    //     _this.$emit("getClickData", params.name);
    //   }
    // });
  },
};
</script>
