<template>
  <div :style="{ height: height, width: width }" class="bg" ref="chart"></div>
</template>
<script>
import {debounce} from "lodash";

const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
      type: Object,
      default: {
        seriesData: [

        ]
      }
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      colors: [
        "rgba(88,113,192,.5)",
        "rgba(158,202,127,0.5)",
        "rgba(241,201,107,0.5)",
        "rgba(223,110,106,0.5)",
        "rgba(133,192,219,0.5)",
        "rgba(89,158,118,0.5)",
        "rgba(235,137,93,0.5)",
        "rgba(147,100,175,.5)",
        "rgba(220,131,200,0.5)",
        "rgba(116,251,253,0.5)",
        "rgba(116,250,139,0.5)",
        "rgba(255,253,84,.5)",
        "rgba(210,59,87,0.5)",
        "rgba(97,211,212,0.5)",
        "rgba(98,164,247,0.5)",
        "rgba(231,155,55,.5)",
        "rgba(252,240,80,.5)",
      ], //['#00ff5a', '#26fff7']
      bColors: [
        "rgba(88,113,192,1)",
        "rgba(158,202,127,1)",
        "rgba(241,201,107,1)",
        "rgba(223,110,106,1)",
        "rgba(133,192,219,1)",
        "rgba(89,158,118,1)",
        "rgba(235,137,93,1)",
        "rgba(147,100,175,1)",
        "rgba(220,131,200,1)",
        "rgba(116,251,253,1)",
        "rgba(116,250,139,1)",
        "rgba(255,253,84,1)",
        "rgba(210,59,87,1)",
        "rgba(97,211,212,1)",
        "rgba(98,164,247,1)",
        "rgba(231,155,55,1)",
        "rgba(252,240,80,1)",
      ],
      faultByHourIndex: 0,
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        if (val) {
          this.initCharts();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);

    let _this = this;
  },
  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);

      this.chart.setOption({
        legend: {
          show: true,
          orient: 'vertical',
          left: 40,
          // top: 30,
          bottom: 50,
          textStyle:{
            color: "#5bd5f7",
            fontSize: 14,
          },
          itemWidth: 11 ,
          itemHeight: 3 ,
          z:10
        },
        series: [
          {
            z:20,
            type: "pie",
            minAngle: 15,
            center: ["57%", "50%"],
            radius: ["28%", "42%"],
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: true,
                formatter: (params) => {
                  // return console.log(params)
                  return '{top| '+ params.name + '}{topNum|' + ' ' + (params.value || 0) + '}{unit|'+ '件' +'}\n' +
                      '{item| '+ '待执行' + '}{itemNum1|' + ' ' + (params.data.pending || 0) + '}{unit|'+ '件' +'}\n' +
                      '{item| '+ '执行中' + '}{itemNum2|' + ' ' + (params.data.processing || 0) + '}{unit|'+ '件' +'}\n' +
                      '{item| '+ '已执行' + '}{itemNum3|' + ' ' + (params.data.processed || 0) + '}{unit|'+ '件' +'}'
                },
                rich: {
                  top: {
                    fontSize: 16,
                    color: '#fff',
                  },
                  topNum:{
                    fontSize: 22,
                    color: '#00ffff',
                    fontWeight:'bold'
                  },
                  item:{
                    fontSize: 14,
                    color: '#fff',
                  },
                  itemNum1:{
                    fontSize: 16,
                    color: '#e7dc58',
                  },
                  itemNum2:{
                    fontSize: 16,
                    color: '#29f5af',
                  },
                  itemNum3:{
                    fontSize: 16,
                    color: '#1488ff',
                  },
                  unit:{
                    fontSize: 14,
                    color: '#fff',
                  },
                }
              }
            },
            labelLine: {
              normal: {
                show: false,
                color: "rgb(0, 255, 255)",
                length: 15,
                length2: 30,
              },
              emphasis: {
                show: true,

              }
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  return _this.colors[params.dataIndex];
                },
              },
            },
            data: _this.chartData.seriesData.map((val, ind) => {
              return {
                itemStyle: {
                  borderColor: _this.bColors[ind],
                },
                ...val
              }
            }),
          },
        ],
      });

      this.chart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: this.faultByHourIndex,
      });

      this.chart.on("mouseover", function (d) {
        _this.chart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
        });
        _this.chart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: d.dataIndex,
        });
        _this.faultByHourIndex = d.dataIndex;
      });

      this.chart.on("mouseout", function () {
        _this.chart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: _this.faultByHourIndex,
        });
        _this.chart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
        });

        _this.chart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: _this.faultByHourIndex,
        });
      });
    },
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
};
</script>
