<template>
  <div class="public">
    <div class="container HomeSupervise">
      <!-- 四色码 -->
      <div class="idiot-one">
        <div class="box">
          <div
            class="box-left-top"
            v-for="(val, ind) in boxLeftData"
            :key="ind"
          >
            <p>
              {{ val.name }}：<span>{{ val.val }}</span> {{ val.unit }}
            </p>
          </div>
        </div>
        <div class="box">
          <div class="box-left-top">
            <p>
              总任务：<span>{{ this.boxCenterData }}</span> 件
            </p>
          </div>
        </div>
        <div class="box">
          <div
            class="box-right-item"
            v-for="(val, ind) in boxRightData"
            :key="ind"
          >
            <p>
              {{ val.name }}<br />
              <span :class="`num${ind + 1}`">{{ val.val }}</span> {{ val.unit }}
            </p>
          </div>
        </div>
      </div>
      <!-- 任务协同占比情况 饼图 -->
      <div class="idiot-two echarts pie-box">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>任务协同分布图</span>
          </div>
          <!-- <el-button @click="toOnePie()" v-show="isTowPie" type="text" style="position: absolute;top: 6px;right: 130px;font-size: 14px;">返回</el-button> -->
        </div>
        <div class="clearfix pie-chart-box">
          <div class="pie-charts">
            <div class="pie-charts-back"></div>
            <div class="pie-charts-back-child"></div>
            <PieTaskCood :chartData="pieChartData1" />
          </div>
          <div class="pie-charts">
            <div class="pie-charts-back right"></div>
            <PieLegend :chartData="pieChartData2" />
          </div>
        </div>
      </div>

      <!-- 任务协同新增变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>任务协同新增变化趋势</span>
          </div>
          <div class="h-selct" :style="{ right: '650px' }">
            <el-select v-model="hairpinType3" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in sectionList"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
          <el-radio-group v-model="tabPosition2" class="tab-select-btn">
            <el-radio-button label="1">任务</el-radio-button>
            <el-radio-button label="2">人员</el-radio-button>
          </el-radio-group>
          <div class="echarts-but">
            <el-radio-group v-model="radio1">
              <el-radio-button
                v-for="item in radioData1"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="charts">
          <LineChart
            :chartData="lineChartData"
            :legendData="legendData2"
            v-loading="lineChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :units="unitData2"
            :yAxis="['']"
            :yAxisIndex="['0']"
            :type="radio1"
          ></LineChart>
          <!-- <BarChart :chartData="barChartData" :legendData="['存管', '保险']"></BarChart> -->
        </div>
      </div>
      <!-- 任务协同变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>任务协同累计变化趋势</span>
          </div>
          <div class="h-selct" :style="{ right: '650px' }">
            <el-select v-model="hairpinType4" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in sectionList"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
          <el-radio-group v-model="tabPosition3" class="tab-select-btn">
            <el-radio-button label="1">任务</el-radio-button>
            <el-radio-button label="2">人员</el-radio-button>
          </el-radio-group>
          <div class="echarts-but">
            <el-radio-group v-model="radio2">
              <el-radio-button
                v-for="item in radioData1"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="charts">
          <LineChart
            :chartData="barChartData"
            :legendData="legendData"
            v-loading="barChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :units="unitData"
            :yAxis="['']"
            :yAxisIndex="['0']"
            :istrue="this.tabPosition3 + ''"
            :type="radio2"
          ></LineChart>
        </div>
      </div>
      <!-- 任务协同按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>任务协同区域分布数量统计</span>
          </div>

          <div class="h-selct">
            <el-select v-model="hairpinType1" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in sectionList"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
          >
            <el-table-column
              prop="regionName"
              :key="0"
              label="地区"
              sortable
              width="290"
            >
            </el-table-column>
            <template>
              <el-table-column prop="money" :key="1" label="协同部门" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.deptNum }}</span
                  ><i
                    :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="custodyMoney"
                :key="2"
                label="执行人员"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.userNum }}</span
                  ><i
                    :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                :key="3"
                label="总任务"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.taskTotalNum }}</span
                  ><i
                    :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                :key="4"
                label="待执行"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.taskStandByNum }}</span
                  ><i
                    :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                :key="5"
                label="执行中"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.taskUnderWayNum }}</span
                  ><i
                    :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                :key="6"
                label="已执行"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.taskCompletedNum }}</span
                  ><i
                    :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                  >
                </template>
              </el-table-column>
            </template>
          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
              >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 任务协同按领域/行业统计 （不按领域统计） 表格 -->

    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/line2"; // 四码折线图
import PieSmallChart from "@/components/charts/pieSmall"; //饼图
import LinebarChart from "@/components/charts/linebardz1"; // 折线+柱状+滚动条
import BarChart from "@/components/charts/bar";
import DateSelect from "@/components/dateSelect/dateSelect";
import HomeSuperviseItem from "@/components/homeSupervise-item/homeSupervise-item";
import PieTaskCood from "@/components/charts/pieTaskCood.vue";
import PieLegend from "@/components/charts/PieLegend.vue";
import {
  statisticsTaskStatus,
  taskSituationByDep,
  departmentList,
  taskAdd,
  taskCumulative,
  taskCityStatistics,
} from "@/api/homeTaskCoordination.js";

export default {
  name: "HomeSupervise",
  props: ["regionName", "cityName", "flag", "regionId", "regionType"],
  data() {
    return {
      lineChart1: false,
      barChart2: false,
      // totalMoney: "0",
      outing: [],
      sectionList: [],
      forCodeData: [],
      typeName: "资金",
      typeName2: "资金",
      typeName3: "资金",
      typeName4: "资金",
      typeName5: "资金",

      tabPosition2: 1,
      tabPosition3: 1,

      isTowPie: false,

      barChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "存管金额",
            value: [],
          },
          {
            name: "提现金额",
            value: [],
          },
        ],
      },
      legendData: ["总任务", "待执行", "执行中", "已执行"],
      unitData: ["件", "件", "件", "件"],
      radio1: "1",
      radio2: "1",
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      lineChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "存管商户发卡金额",
            data: [],
          },
          {
            name: "保险商户发卡金额",
            data: [],
          },
        ],
      },
      legendData2: ["总任务", "待执行", "执行中", "已执行"],
      unitData2: ["件", "件", "件", "件"],
      hairpinType1: "",
      hairpinType3: "",
      hairpinType4: "",
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      showAll3: false,
      tableDataAll3: [],
      boxLeftData: [
        {
          name: "协同部门",
          val: "678",
          unit: "个",
        },
        {
          name: "执行人员",
          val: "62178",
          unit: "人",
        },
      ],
      boxCenterData: 0,
      boxRightData: [
        {
          name: "待执行",
          val: "678",
          unit: "件",
        },
        {
          name: "执行中",
          val: "62178",
          unit: "件",
        },
        {
          name: "已执行",
          val: "62178",
          unit: "件",
        },
      ],
      pieChartData1: {
        seriesData: [],
      },
      pieChartData2: {
        seriesData: [
          {
            name: "政法委",
            value: 37,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "政府办",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "信访办",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "公安分局",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "市场监管局",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "体育局",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "教育局",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "商务委",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "交通办",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "科委",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "卫健委",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "金融办",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "委网信办",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "人社局",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "文旅局",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "应急管理局",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
          {
            name: "消防支队",
            value: 50,
            pending: "",
            processing: "",
            processed: "",
          },
        ],
      },
      date: this.dataAdd0(new Date().getTime() - 24 * 60 * 60 * 1000),
    };
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        cityName: this.cityName,
        areaName: this.areaName,
        flag: this.flag,
        regionId: this.regionId,
        regionType: this.regionType,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    radio1: function (val) {
      this.taskAdd();
    },
    hairpinType3: function (val) {
      this.taskAdd();
    },
    hairpinType4: function (val) {
      this.taskCumulative();
    },
    tabPosition2: function (val) {
      this.taskAdd();
      this.legendData2 =
        val == 1 ? ["总任务", "待执行", "执行中", "已执行"] : ["执行人员"];
      this.unitData2 = val == 1 ? ["件", "件", "件", "件"] : ["人"];
      this.typeName2 = val == 1 ? "任务" : "人员";
    },
    radio2: function (val) {
      this.taskCumulative();
    },
    tabPosition3: function (val) {
      this.taskCumulative();
      this.legendData =
        val == 1 ? ["总任务", "待执行", "执行中", "已执行"] : ["执行人员"];
      this.unitData = val == 1 ? ["件", "件", "件", "件"] : ["人"];
      this.typeName3 = val == 1 ? "任务" : "人员";
    },

    hairpinType1: function (val) {
      this.taskCityStatistics();
    },

    showAll: function (val) {
      this.showAllData();
    },

  },
  mounted() {
    if (sessionStorage.getItem("initParams") === "init") {
      this.intData();
    }
  },
  beforeDestroy() {
    window.sessionStorage.setItem("initParams", "init");
  },
  methods: {
    //日期补0，传时间对象或时间戳
    dataAdd0(date) {
      var year = new Date(date).getFullYear() + "-";
      var month = (new Date(date).getMonth() + 1 + "").padStart(2, "0") + "-";
      var day = (new Date(date).getDate() + "").padStart(2, "0");
      return year + month + day;
    },
    intData() {
      this.departmentList();
      this.statisticsTaskStatus();
      this.taskSituationByDep();
      this.taskAdd();
      this.taskCumulative();
      this.taskCityStatistics();
    },
    departmentList() {
      departmentList().then((res) => {
        console.log(res);

        this.sectionList = res.data.map((v) => {
          return {
            name: v.name,
            haripinType: v.id,
          };
        });
      });
    },
    statisticsTaskStatus() {
      statisticsTaskStatus({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          console.log(res.data);
          this.boxLeftData[0].val = res.data.departmentNum;
          this.boxLeftData[1].val = res.data.userNum;
          this.boxRightData[0].val = res.data.taskStandByNum;
          this.boxRightData[1].val = res.data.taskUnderWayNum;
          this.boxRightData[2].val = res.data.taskCompletedNum;
          this.boxCenterData = res.data.taskTotalNum;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    taskSituationByDep() {
      let _this = this;
      taskSituationByDep({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        console.log("饼图", res);
        this.pieChartData1.seriesData = res.data.map((v) => {
          return {
            name: v.departmentName,
            value: v.userNum,
            com: "人",
          };
        });
        this.pieChartData2.seriesData = res.data.map((v) => {
          return {
            name: v.departmentName,
            value: v.userNum,
            pending: v.taskStandByNum,
            processing: v.taskUnderWayNum,
            processed: v.taskCompletedNum,
          };
        });
      });
    },
    taskCumulative() {
      this.barChart2 = true;
      taskCumulative({
        departmentId: this.hairpinType4,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        taskOrUser: this.tabPosition3,
        type: this.radio2,
      }).then((res) => {
        //console.log('任务协同累计变化趋势',res)
        this.barChart2 = false;
        if (res.data != null) {
          this.barChartData = {
            xAxisData: res.data.x_axis,
            seriesData: [
              {
                name:
                  this.tabPosition3 == 1
                    ? "总任务"
                    : this.tabPosition3 == 2
                    ? "执行人员"
                    : "",
                data:
                  this.tabPosition3 == 1
                      ? res.data.totalNum
                      : this.tabPosition3 == 2
                      ? res.data.userNum
                    : [],
              },
              {
                name:
                  this.tabPosition3 == 1
                    ? "待执行"
                    : this.tabPosition3 == 2
                    ? ""
                    : "",
                data:
                  this.tabPosition3 == 1
                    ? res.data.status1Num
                    : this.tabPosition3 == 2
                    ? []
                    : [],
              },
              {
                name:
                  this.tabPosition3 == 1
                    ? "执行中"
                    : this.tabPosition3 == 2
                    ? ""
                    : "",
                data:
                  this.tabPosition3 == 1
                    ? res.data.status2Num
                    : this.tabPosition3 == 2
                    ? []
                    : [],
              },
              {
                name:
                  this.tabPosition3 == 1
                    ? "已执行"
                    : this.tabPosition3 == 2
                    ? ""
                    : "",
                data:
                  this.tabPosition3 == 1
                    ? res.data.status3Num
                    : this.tabPosition3 == 2
                    ? []
                    : [],
              },
            ],
          };
        } else {
          this.barChartData = {
            xAxisData: [],
            seriesData: [
              {
                name:
                  this.tabPosition3 == 1
                    ? "存管商户发卡金额"
                    : this.tabPosition3 == 2
                    ? "存管商户数量"
                    : "",
                data: [],
              },
              {
                name:
                  this.tabPosition3 == 1
                    ? "保险商户发卡金额"
                    : this.tabPosition3 == 2
                    ? "保险商户数量"
                    : "",
                data: [],
              },
            ],
          };
        }
      });
    },
    taskAdd() {
      this.lineChart1 = true;
      taskAdd({
        departmentId: this.hairpinType3,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
        taskOrUser: this.tabPosition2,
      }).then((res) => {
        console.log("任务协同新增变化趋势", res);
        this.lineChart1 = false;
        if (res.data != null) {
          this.lineChartData = {
            xAxisData: res.data.x_axis,
            seriesData: [
              {
                name:
                  this.tabPosition2 == 1
                    ? "总任务"
                    : this.tabPosition2 == 2
                    ? "执行人员"
                    : "",
                data:
                  this.tabPosition2 == 1
                    ? res.data.totalNum
                    : this.tabPosition2 == 2
                    ? res.data.userNum
                    : [],
              },
              {
                name:
                  this.tabPosition2 == 1
                    ? "待执行"
                    : this.tabPosition2 == 2
                    ? ""
                    : "",
                data:
                  this.tabPosition2 == 1
                    ? res.data.status1Num
                    : this.tabPosition2 == 2
                    ? []
                    : [],
              },
              {
                name:
                  this.tabPosition2 == 1
                    ? "执行中"
                    : this.tabPosition2 == 2
                    ? ""
                    : "",
                data:
                  this.tabPosition2 == 1
                    ? res.data.status2Num
                    : this.tabPosition2 == 2
                    ? []
                    : [],
              },
              {
                name:
                  this.tabPosition2 == 1
                    ? "已执行"
                    : this.tabPosition2 == 2
                    ? ""
                    : "",
                data:
                  this.tabPosition2 == 1
                    ? res.data.status3Num
                    : this.tabPosition2 == 2
                    ? []
                    : [],
              },
            ],
          };
        }
      });
    },
    taskCityStatistics() {
      let _this = this;
      this.loading1 = true;
      taskCityStatistics({
        departmentId: this.hairpinType1,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按城市/地区统计',res)
        res.data.forEach((item) => {
          item.hasChildren = true;
        });
        this.loading1 = false;
        this.tableDataAll = [];
        this.$nextTick(() => {
          _this.tableData1 = res.data || [];
          _this.tableDataAll = _this.tableData1.slice(0, 10);
          _this.showAllData();
        });
      });
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    loadNextNode1(tree, treeNode, resolve) {
      // console.log(tree, 'treeNode, resolve')
      taskCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
      }).then((res) => {
        // res.data = res.data.map(item => {
        //   item.hasChildren = true;
        //   return item
        // })
        // console.log('----------------------',res.data)
        resolve(res.data || []);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
  },
  components: {
    LineChart,
    LinebarChart,
    BarChart,
    PieSmallChart,
    DateSelect,
    HomeSuperviseItem,
    PieTaskCood,
    PieLegend,
  },
};
</script>

<style lang="scss" scoped >
.HomeSupervise {
  // 四色码
  .idiot-one {
    display: flex;
    justify-content: space-around;
    height: 160px;
    margin: 0 30px 30px 30px;
    border: 1px solid #17688d;
    background: linear-gradient(
      to bottom,
      rgba(43, 202, 255, 0.1),
      #0000,
      rgba(43, 202, 255, 0.1)
    );

    .box-left-top {
      padding-left: 56px;
      margin-bottom: 26px;
      line-height: 1;
      & > p {
        font-size: 18px;
        display: flex;
        align-items: center;
        span {
          color: #208dfd;
          font-size: 30px;
          margin-right: 5px;
        }
      }
    }

    .box:nth-child(1) {
      width: 33.3%;
      border-right: 1px solid transparent;
      border-image: linear-gradient(transparent, #17688d, transparent) 0 30;
      margin-top: 36px;
    }
    .box:nth-child(2) {
      width: 30.3%;
      .box-left-top {
        padding-left: 56px;
        line-height: 160px;
        & > p {
          span {
            color: #5af4b1;
            margin-right: 5px;
          }
        }
      }
    }
    .box:nth-child(3) {
      width: 36.3%;
      padding-right: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .box-right-item {
        font-size: 14px;
        text-align: center;
        & > p {
          span {
            font-weight: bold;
            font-size: 16px;
            &.num1 {
              color: #ffff00;
            }
            &.num2 {
              color: #5af4b1;
            }
            &.num3 {
              color: #007dfd;
            }
          }
        }
      }
    }
  }
  .pie-box {
    background: url("../assets/imgs/pie-bg3.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0;
    .echarts-box {
      position: relative;
      .echarts-tit {
        .txt {
          margin-left: 30px;
          line-height: 40px;
          i {
            font-weight: bold;
          }
        }
      }
    }
    .pie-chart-box {
      .txt {
        width: 50%;
        float: left;
        box-sizing: border-box;
        text-align: center;
        margin-top: 50px;
        span:nth-child(1) {
          display: block;
          font-size: 18px;
        }
        span:nth-child(2) {
          display: block;
          font-size: 30px;
          color: #5bd5f7;
          i {
            font-size: 18px;
            margin-left: 10px;
          }
        }
        &:nth-child(2) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
              transparent,
              transparent,
              #17688d,
              #17688d,
              transparent,
              transparent
            )
            0 30 30 30;
          span:nth-child(2) {
            color: #208dfd;
          }
        }
      }
      .pie-charts {
        float: left;
        width: 50%;
        height: 380px;
        box-sizing: border-box;
        position: relative;
        // background: url("../assets/imgs/pie-bg2.png") no-repeat center center;
        // background-size: 55%;
        &:nth-child(1) {
          width: 40%;
          // border-right: 1px solid transparent;
          // border-image: linear-gradient(
          //     #17688d,
          //     #17688d,
          //     transparent,
          //     transparent
          //   )
          //   30 30;
          border-right: 1px solid transparent;
          border-image: linear-gradient(transparent, #17688d, transparent) 0 30;
        }
        &:nth-child(2) {
          width: 60%;
        }
        .pie-charts-back {
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -91px;
          margin-top: -91px;
          width: 182px;
          height: 182px;
          border-radius: 50%;
          border: 1px solid #154f95;
          &.right {
            margin-left: -43px;
          }
        }
        .pie-charts-back-child {
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -42px;
          margin-top: -42px;
          width: 84px;
          height: 84px;
          border-radius: 50%;
          border: 1px solid #039aa4;
        }
      }
    }
  }
  .tab-select-btn {
    position: absolute;
    top: 6px;
    right: 0;
    &.right-size {
      right: 120px;
    }
  }
}
</style>
